import Vue from 'vue';
import Vuex from 'vuex';
import Localbase from 'localbase';

const db = new Localbase('db');
db.config.debug = false;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appTitle: 'PCA',
    token: '',
    userid: '',
    moduleOneAttempts: 0,
    moduleTwoAttempts: 0,
    moduleOnePassed: false,
    moduleTwoPassed: false,
    moduleOneAnswers: {
      a1: false,
      b1: false,
      c1: false,
      d1: false,
      e1: false,
      a2: false,
      b2: false,
      c2: false,
      d2: false,
      e2: false,
      a3: false,
      b3: false,
      c3: false,
      d3: false,
      a4: false,
      b4: false,
      c4: false,
      a5: false,
      b5: false,
      c5: false,
      a6: false,
      b6: false,
      c6: false,
    },
    moduleTwoAnswers: {
      a1: false,
      b1: false,
      c1: false,
      d1: false,
      a2: false,
      b2: false,
      c2: false,
      a3: false,
      b3: false,
      c3: false,
      d3: false,
      a4: false,
      b4: false,
      c4: false,
      d4: false,
      a5: false,
      b5: false,
      c5: false,
      d5: false,
      e5: false,
      a6: false,
      b6: false,
      c6: false,
      d6: false,
      e6: false,
    },
  },
  mutations: {
    setCredentials(state, credentials) {
      state.token = credentials.json.token;
      state.userid = credentials.user;
    },
    unsetCredentials(state) {
      state.token = '';
      state.userid = '';
    },
    useCredentials(state, credentials) {
      state.token = credentials.token;
      state.userid = credentials.id;
    },
    moduleOneAttempts(state, attempts) { state.moduleOneAttempts = attempts },
    moduleTwoAttempts(state, attempts) { state.moduleTwoAttempts = attempts },
    moduleOnePassed(state, result) { state.moduleOnePassed = result },
    moduleTwoPassed(state, result) { state.moduleTwoPassed = result },
    answerM1A1(state, answer) { state.moduleOneAnswers.a1 = answer; },
    answerM1B1(state, answer) { state.moduleOneAnswers.b1 = answer; },
    answerM1C1(state, answer) { state.moduleOneAnswers.c1 = answer; },
    answerM1D1(state, answer) { state.moduleOneAnswers.d1 = answer; },
    answerM1E1(state, answer) { state.moduleOneAnswers.e1 = answer; },
    answerM1A2(state, answer) { state.moduleOneAnswers.a2 = answer; },
    answerM1B2(state, answer) { state.moduleOneAnswers.b2 = answer; },
    answerM1C2(state, answer) { state.moduleOneAnswers.c2 = answer; },
    answerM1D2(state, answer) { state.moduleOneAnswers.d2 = answer; },
    answerM1E2(state, answer) { state.moduleOneAnswers.e2 = answer; },
    answerM1A3(state, answer) { state.moduleOneAnswers.a3 = answer; },
    answerM1B3(state, answer) { state.moduleOneAnswers.b3 = answer; },
    answerM1C3(state, answer) { state.moduleOneAnswers.c3 = answer; },
    answerM1D3(state, answer) { state.moduleOneAnswers.d3 = answer; },
    answerM1A4(state, answer) { state.moduleOneAnswers.a4 = answer; },
    answerM1B4(state, answer) { state.moduleOneAnswers.b4 = answer; },
    answerM1C4(state, answer) { state.moduleOneAnswers.c4 = answer; },
    answerM1A5(state, answer) { state.moduleOneAnswers.a5 = answer; },
    answerM1B5(state, answer) { state.moduleOneAnswers.b5 = answer; },
    answerM1C5(state, answer) { state.moduleOneAnswers.c5 = answer; },
    answerM1A6(state, answer) { state.moduleOneAnswers.a6 = answer; },
    answerM1B6(state, answer) { state.moduleOneAnswers.b6 = answer; },
    answerM1C6(state, answer) { state.moduleOneAnswers.c6 = answer; },
    answerM2A1(state, answer) { state.moduleTwoAnswers.a1 = answer; },
    answerM2B1(state, answer) { state.moduleTwoAnswers.b1 = answer; },
    answerM2C1(state, answer) { state.moduleTwoAnswers.c1 = answer; },
    answerM2D1(state, answer) { state.moduleTwoAnswers.d1 = answer; },
    answerM2A2(state, answer) { state.moduleTwoAnswers.a2 = answer; },
    answerM2B2(state, answer) { state.moduleTwoAnswers.b2 = answer; },
    answerM2C2(state, answer) { state.moduleTwoAnswers.c2 = answer; },
    answerM2A3(state, answer) { state.moduleTwoAnswers.a3 = answer; },
    answerM2B3(state, answer) { state.moduleTwoAnswers.b3 = answer; },
    answerM2C3(state, answer) { state.moduleTwoAnswers.c3 = answer; },
    answerM2D3(state, answer) { state.moduleTwoAnswers.d3 = answer; },
    answerM2A4(state, answer) { state.moduleTwoAnswers.a4 = answer; },
    answerM2B4(state, answer) { state.moduleTwoAnswers.b4 = answer; },
    answerM2C4(state, answer) { state.moduleTwoAnswers.c4 = answer; },
    answerM2D4(state, answer) { state.moduleTwoAnswers.d4 = answer; },
    answerM2A5(state, answer) { state.moduleTwoAnswers.a5 = answer; },
    answerM2B5(state, answer) { state.moduleTwoAnswers.b5 = answer; },
    answerM2C5(state, answer) { state.moduleTwoAnswers.c5 = answer; },
    answerM2D5(state, answer) { state.moduleTwoAnswers.d5 = answer; },
    answerM2E5(state, answer) { state.moduleTwoAnswers.e5 = answer; },
    answerM2A6(state, answer) { state.moduleTwoAnswers.a6 = answer; },
    answerM2B6(state, answer) { state.moduleTwoAnswers.b6 = answer; },
    answerM2C6(state, answer) { state.moduleTwoAnswers.c6 = answer; },
    answerM2D6(state, answer) { state.moduleTwoAnswers.d6 = answer; },
    answerM2E6(state, answer) { state.moduleTwoAnswers.e6 = answer; },
  },
  actions: {
    setCredentials({ commit }, credentials) {
      commit('setCredentials', credentials);
      db.collection('users').doc({ id: credentials.user }).get().then((document) => {
        if (document) {
          db.collection('users').doc({ id: document.id }).set({
            id: credentials.user,
            token: credentials.json.token,
          });
        } else {
          db.collection('users').add({
            id: credentials.user,
            token: credentials.json.token,
          });
        }
      });
    },
    unsetCredentials({ commit }) {
      commit('unsetCredentials');
      db.collection('users').delete();
    },
  },
  getters: {},
});
