import Vue from 'vue';
import VueRouter from 'vue-router';
import Access from '@/views/Access.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Access',
    component: Access,
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('../views/Agenda.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:id',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/module-one',
    name: 'ModuleOne',
    component: () => import('../views/ModuleOne.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/module-one-content',
    name: 'ModuleOneContent',
    component: () => import('../views/ModuleOneContent.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/module-one-quiz',
    name: 'ModuleOneQuiz',
    component: () => import('../views/ModuleOneQuiz.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/module-two',
    name: 'ModuleTwo',
    component: () => import('../views/ModuleTwo.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/module-two-content',
    name: 'ModuleTwoContent',
    component: () => import('../views/ModuleTwoContent.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/module-two-quiz',
    name: 'ModuleTwoQuiz',
    component: () => import('../views/ModuleTwoQuiz.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: () => import('../views/TermsConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  /*
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  */
];

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
  
});

export default router;
