<template>
  <div id="app">
    <p class="top-line">
      This site is initiated and funded by IPSEN and is intended for UK specialist registrars in urology.
    </p>
    <div class="pad-left pad-right">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6">
          <a href="/" aria-current="page" class="router-link-exact-active router-link-active">
            <img
              src="./assets/PCa_icon.png"
              alt=""
              class="float-left"
              width="90" />
          </a>
        </div>
        <div class="cell small-12 medium-6">
          <img
            src="./assets/IPSEN_logo.png"
            alt=""
            class="float-right marg-right"
            width="260" />
        </div>
      </div>
    </div>
    <div class="pad-left pad-right pad-top-s clear">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6">
          <p><strong>Prospective Consultants Academy 2022</strong></p>
        </div>
        <div class="cell small-12 medium-6">
          <div class="nav-container">
            <span
              class="menu-trigger"
              v-if="showMenu === false"
              @click="showMenu = true">Show Menu</span>
            <span
              class="menu-trigger"
              v-if="showMenu === true"
              @click="showMenu = false">Hide Menu</span>
            <ul v-if="showMenu === true" class="nav">
              <li>
                <router-link to="/home">Home</router-link>
              </li>
              <li>
                <router-link to="/module-one">
                  Module 1: What happens when things go wrong?
                </router-link>
              </li>
              <li>
                <router-link to="/module-two">
                  Module 2: Navigating the NHS
                </router-link>
              </li>
              <li>
                <router-link to="/agenda">
                  Masterclass 2022 agenda
                </router-link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <router-view></router-view>
    <div class="bg--white">
      <!--
        <div class="grid-container pad-bottom--large">
          <div class="grid-x grid-padding-x pad-top">
            <div class="cell small-12">
              <div class="bordered black pad pad-left--xl pad-right--xl">
                <p class="text-center text-black marg-bottom--none">
                  Adverse events should be reported. Reporting forms and information can be found at 
                  <span class="mhra-link text-black">www.mhra.gov.uk/yellowcard</span>
                  or search for MHRA Yellow Card in Google Play or Apple App Store. Adverse events
                  should also be reported to Ipsen via email at
                  <a href="mailto:pharmacovigilance.uk-ie@ipsen.com" class="text-black email-link">
                    pharmacovigilance.uk-ie@ipsen.com</a> or phone 01753 627777
                </p>
              </div>
            </div>
          </div>
        </div>
      -->
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <hr class="hr-black">
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-3">
          <p class="text-small text-black">
            DRSC-UK-000021 Date of preparation: October 2022
          </p>
        </div>
        <div class="cell small-12 medium-6">
          <ul class="footer-nav">
            <router-link tag="li" to="/terms-conditions">Terms &amp; Conditions</router-link>
            <router-link tag="li" to="/privacy-policy">Privacy Policy</router-link>
          </ul>
        </div>
        <div class="cell small-12 medium-3">
          <img
            src="./assets/ipsen_education_logo.jpg"
            alt=""
            class="float-right marg-right"
            style="position:relative;top:-8px;"
            width="140" />
          <img
              src="./assets/IPSEN_EDU_EXC_Orange_Pink.png"
              alt=""
              class="float-right marg-left"
              width="25" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showMenu: false,
    };
  },
}
</script>

<style>
  body {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(./assets/Gradient_background.png);
    min-height: 1000px;
    min-width: 960px;
  }

  body::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(./assets/banner_wave.png);
    height: 286px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  a, body, div, h1, h2, h3, h4, h5, h6, html, label, li, ol, p, span, ul {
    font-family: Arial,Helvetica,sans-serif;
    color: #333;
  }

  .clear {
    clear: both;
  }

  .grid-container, .grid-container.fluid {
    padding-right: .9375rem;
    padding-left: .9375rem;
    margin-left: auto;
    margin-right: auto;
  }
  .grid-container {
    max-width: 80rem;
  }

  #app {
    position: relative;
    z-index: 2;
    background: transparent;
  }

  .content {
    padding-top: 140px;
    min-width: 1300px;
  }

  .top-line {
    margin: 0;
    padding: 2px;
    text-align: center;
    color: #000;
    font-size: 15px;
  }

  .button {
    background-color: #b75ca1;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 11px 20px;
    margin-top: -1px;
    border-radius: 5px;
  }

  .pad {
    padding: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .pad-right {
    padding-right: 20px;
  }

  .pad-right--xl {
    padding-right: 60px;
  }

  .pad-left {
    padding-left: 20px;
  }

  .pad-left--xl {
    padding-left: 60px;
  }

  .pad-top {
    padding-top: 20px;
  }

  .pad-top-s {
    padding-top: 10px;
  }

  .pad-top--xl {
    padding-top: 60px;
  }

  .pad-top--xxl {
    padding-top: 80px;
  }

  .pad-top--xxxl {
    padding-top: 100px;
  }

  .pad-top--xxxl {
    padding-top: 120px;
  }

  .pad-top--xxxxl {
    padding-top: 140px;
  }

  .pad-top--xxxxxl {
    padding-top: 160px;
  }

  .pad-top--xxxxxxl {
    padding-top: 180px;
  }

  .pad-bottom {
    padding-bottom: 20px;
  }

  .pad-bottom--large {
    padding-bottom: 40px;
  }

  .pad-bottom--xl {
    padding-bottom: 60px;
  }

  .pad-bottom--xxl {
    padding-bottom: 80px;
  }

  .pad-bottom--xxxl {
    padding-bottom: 100px;
  }

  .pad-bottom--xxxxl {
    padding-bottom: 120px;
  }

  .pad-bottom--vxl {
    padding-bottom: 200px;
  }

  .text-small {
    font-size: 13px;
  }

  .text-right {
    text-align: right;
  }

  .text-white {
    color: #FFF;
  }

  .marg-left {
    margin-left: 20px;
  }

  .marg-right {
    margin-right: 20px;
  }

  .float-right {
    float: right !important;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
  }

  img {
    border-style: none;
  }

  .large-heading {
    font-size: 54px;
    font-weight: 700;
  }

  input[type="email"], input[type="password"], input[type="text"] {
    background: transparent;
    border-radius: 0;
    color: #fff;
  }

  .bg--white {
    background-color: #fff;
  }

  .bg--transparent {
    background: transparent!important;
  }

  .border-right {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #000;
  }

  .border-white {
    border-right-color: #FFF;
  }

  .bordered {
    border: 1px solid #fff;
  }
  .bordered.black {
    border: 1px solid #000;
  }

  .text-black {
    color: #000;
  }
  .text-grey {
    color: #333;
  }
  .text-purple {
    color: #bf549b;
  }
  .text-center {
    text-align: center;
  }

  .footer-nav {
    margin: 0;
    padding: 0;
  }

  .footer-nav li {
    float: left;
    list-style-type: none;
    margin-right: 20px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    color: #000;
  }

  .footer-nav li a {
    color: #000;
  }

  a {
    line-height: inherit;
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
  }

  .agenda-table {
    border-collapse: collapse;
    border: none;
    width: 100%;
  }

  .agenda-table tbody, .agenda-table thead {
    border: none;
  }

  .agenda-table tbody td,
  .agenda-table tbody th,
  .agenda-table tbody tr,
  .agenda-table thead td,
  .agenda-table thead th,
  .agenda-table thead tr {
    background-color: hsla(0,0%,94.1%,.3);
    color: #000;
    vertical-align: top;
    border: none;
    border-right-color: currentcolor;
    border-right-style: none;
    border-right-width: medium;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-left-color: currentcolor;
    border-left-style: none;
    border-left-width: medium;
    border-bottom: 2px solid #fff;
    border-left: none;
    border-right: none;
    font-size: 14px;
  }

  .relative {
    position: relative;
  }

  .tabs {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  .tabs li {
    float: left;
    width: calc(100% / 8);
    padding: 5px 0;
    background-color: #b75ca1;
    text-align: center;
    color: #FFF;
    font-weight: bold;
    font-size: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
  }

  .tabs.nine li {
    width: calc(100% / 10);
  }

  .tabs li:focus,
  .tabs li:hover,
  .tabs li.active {
    background-color: #f18a16;
  }

  .button.rounded {
    border-radius: 5px;
  }

  .button.lower {
    text-transform: none;
  }

  .mini-tab {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -144px;
  }

  .mini-tab.right {
    margin-left: 235px;
  }

  .mini-tab.far-right {
    margin-left: 355px;
  }

  .mini-tab li {
    float: left;
    width: 80px;
    padding: 2px 0;
    list-style-type: none;
    background-color: #b75ca1;
    text-align: center;
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
  }

  .mini-tab li.active,
  .mini-tab li:focus,
  .mini-tab li:hover {
    background-color: #f18a16;
  }

  .question {
    display: block;
    font-size: 20px;
    padding-bottom: 20px;
  }

  .guidance {
    display: block;
    font-style: italic;
    font-size: 16px;
    padding-bottom: 10px;
  }

  .answer {
    display: block;
    font-size: 18px;
  }

  .notes {
    font-size: 11px;
  }

  .notes a {
    color: #333;
  }

.menu-trigger {
  float: right;
  margin-right: 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: #bf549b;
  color:#FFF;
  padding: 5px;
  border-radius: 5px;
}

  .nav {
    position: absolute;
    top: 30px;
    right: 0;
    clear: both;
    margin: 0;
    padding: 10px 20px 20px 20px;
    float: right;
    background-color: #bf549b;
    border-radius: 5px;
  }

  .nav li {
    list-style-type: none;
    font-weight: bold;
    cursor: pointer;
    text-align: right;
    padding: 5px 0;
  }

  .nav li a {
    color: #FFF;
  }

  .nav li a:hover,
  .nav li a:focus {
    text-decoration: underline;
  }

  .nav-container {
    position:relative;
  }

  .agenda-details {
    text-align: center;
    font-size: 22px;
    margin-bottom: 0;
  }
</style>
