<template>
  <div class="content pad-bottom--vxl">
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell pad-top--xxl">
          <h1 class="large-heading pad-bottom text-white">Access</h1>
          <p class="text-white">Please enter the code you have been supplied with below to access the website</p>
          <div class="grid-x grid-padding-x pad-top">
            <div class="cell small-12 medium-2">
              <label class="text-white">Access code</label>
            </div>
            <div class="cell small-12 medium-4">
              <input v-model="accessCode" type="text" class="bg--transparent">
            </div>
            <div class="cell small-12 medium-2">
              <button @click="evaluateCode" class="button expanded">Enter</button>
            </div>
          </div>
          <div class="grid-x grid-padding-x pad-top">
            <div class="cell small-12">
              <p class="text-white">This site is to be viewed on a desktop/laptop</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Access',
  data() {
    return {
      accessCode: '',
    };
  },
  methods: {
    evaluateCode() {
      if (this.accessCode === 'PCA2022*') {
        this.$router.push('/login');
      }
    },
  },
};
</script>
